<template>
  <div class="wrap" v-if="blogCategory.length">
    <div class="tagsWrap" :class="{ overflow: hasMore }">
      <div
        class="tagItem"
        :class="{ activeTag: activeTag === index }"
        v-for="(item, index) in tagList"
        :key="index"
        @click="clickTag(item, index)"
      >
        <span class="name">{{ item }}</span>
      </div>
    </div>

    <div v-if="hasMore" @click="hasMore = false" class="more">
      <!-- <span>更多</span> -->
      <a-icon type="down"></a-icon>
    </div>
    <div v-else @click="hasMore = true" class="more">
      <!-- <span>收起</span> -->
      <a-icon type="up"></a-icon>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      hasMore: true,
      activeTag: -1,
      blogCategory: []
    };
  },
  computed: {
    tagList() {
      const list = [].concat(['全部'], this.blogCategory);
      // if (this.hasMore) {
      //   return list.slice(0, 4);
      // }
      return list;
    }
  },
  created() {},
  mounted() {
    this.getBlogCategory();
  },
  watch: {},
  methods: {
    async getBlogCategory() {
      const res = await this.$api['home/blogCategory']();
      console.log(res, 'blogCategory');
      if (res.message) {
        this.blogCategory = res.message;
      }
    },
    clickTag(item, index) {
      this.activeTag = index;

      this.$emit('clickTag', item);
    },
    handleInit() {
      this.activeTag = -1;
    }
  }
};
</script>

<style scoped lang="less">
.wrap {
  padding: 10px 0 10px;
  position: relative;
  background: #f9f9f9;
  display: flex;
  flex-direction: row;
}
.tagsWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  padding-right: 20px;
  &.overflow {
    overflow: hidden;
    line-height: 20px;
    max-height: 30px;
    // display: -webkit-box;
    // word-break: break-all;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 1;
    .tagItem {
      margin: 0 10px 0;
    }
  }
  .tagItem {
    background: #ecf0fc;
    border-radius: 6px;
    margin: 0 10px 15px;
    padding: 3px 8px;
    height: 30px;
    font-size: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &.activeTag {
      // background: #fff1f0;
      background: @primary-color;
      border-radius: 5px;
      // border: 1px solid #ffa39e;
      .name {
        // color: #f5222d;
        color: #fff;
      }
    }

    .name {
      color: #4169e2;
    }
  }
}
.more {
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4169e2;

  position: absolute;
  right: 0px;
  top: 15px;
  i {
    font-size: 18px;
  }
}
</style>
