<template>
  <div class="news">
    <van-nav-bar fixed title="专家专栏" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="author-wrap">
      <top-header title="热门专家" padding has-right @handleRight="handleRight"></top-header>
      <div class="author-list">
        <author-item v-for="item in authorData" :key="item.name" :item="item"></author-item>
      </div>
    </div>
    <div style="padding: 16px;z-index:10;background:#fff">
      <a-input-search placeholder="大家都在搜：美国疫情" @search="onSearch" v-model="search" enter-button="搜索">
        <a-icon slot="prefix" type="search"></a-icon>
      </a-input-search>
    </div>
    <div>
      <NewsTag @clickTag="clickTag" ref="tag"></NewsTag>
    </div>
    <div class="list" v-if="isShow">
      <flow-list :url="url" :params="params" :sep="false">
        <template slot-scope="row">
          <expert-item :item="row.itemData"></expert-item>
        </template>
      </flow-list>
    </div>
  </div>
</template>

<script>
import { FlowList, Sticky, NewsItem, TopHeader } from '@/components';

import { backInitMixin } from '@/utils/mixin';
import AuthorItem from '@/components/AuthorItem';
import ExpertItem from './components/ExpertItem';
import NewsTag from './components/NewsTag';
export default {
  components: {
    FlowList,
    Sticky,
    NewsItem,
    AuthorItem,
    TopHeader,
    ExpertItem,
    NewsTag
  },
  mixins: [backInitMixin],
  props: {},
  data() {
    return {
      name: '',
      authorData: [],
      search: '',
      isShow: true,
      url: 'home/authorPost'
    };
  },
  computed: {},

  watch: {},
  created() {
    this.params = {
      limit_start: 0,
      limit_page_length: 10,
      filters: {}
    };
    this.paramsAuthor = {
      limit_start: 0,
      limit_page_length: 10,
      order_by: 'posts desc'
    };
  },
  activated() {
    // this.track()
  },
  mounted() {
    this.getAuthorData();
  },
  methods: {
    async getAuthorData() {
      const res = await this.$api['home/author'](this.paramsAuthor);
      console.log(res, 'author');
      if (res.message) {
        this.authorData = res.message;
      }
    },

    onSearch(value) {
      console.log(value, this.$refs, 'refs');
      this.$refs.tag.handleInit();
      value = value.replace(/(^\s*)|(\s*$)/g, '');

      this.isShow = false;
      this.params.limit_start = 0;
      if (!value) {
        delete this.params.search;
        delete this.params.doctype;
        delete this.params.filters;
        this.url = 'home/authorPost';
      } else {
        this.params.search = this.search;
        this.params.doctype = 'nCov Node';
        this.params.filters = { node_category: this.$utils.newsCategory.expert };
        this.url = 'home/search';
      }

      this.$nextTick(() => {
        this.isShow = true;
      });
    },
    clickTag(item) {
      this.isShow = false;
      this.search = '';
      this.url = 'home/authorPost';
      this.params.limit_start = 0;
      if (item === '全部') {
        delete this.params.filters;
      } else {
        this.params.filters = { insight_category: ['like', item] };
      }
      this.$nextTick(() => {
        this.isShow = true;
      });
    },
    handleRight() {
      this.$router.push({
        path: '/author'
      });
    }
  }
};
</script>

<style scoped lang="less">
.news {
  padding-top: 46px;
  position: relative;
  background: #f0f0f0;
}
.author-wrap {
  background: #fff;
  margin-bottom: 15px;

  .author-list {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
  }
}

.list {
  padding: 0 16px;
  background: #fff;
}
.dot {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background: #c7d4f7;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background: #3f69e2;
  }
}

/deep/ .van-pull-refresh {
  overflow: visible;
}
/deep/ .van-pull-refresh__head {
  z-index: -1;
}
/deep/ .ant-timeline-item-tail {
  border-left-color: #c7d4f7;
}
/deep/ .ant-timeline-item-last > .ant-timeline-item-tail {
  display: block;
}
</style>
