<template>
  <div class="item-wrap" @click="goToDetail(item)">
    <div class="item-tips">
      <img v-lazy="$utils.checkImg(item.avatar)" v-if="item.avatar" alt="" class="avatar" @click.stop="goToAuthor(item)" />
      <img src="@/assets/female.png" v-else alt="" class="avatar" @click.stop="goToAuthor(item)" />
      <span class="item-tips-author" @click.stop="goToAuthor(item)">{{ item.author }}</span>
      <span class="item-tips-time">{{ item.modified | dayjs('YYYY年MM月DD日') }}</span>
    </div>

    <div class="bg-wrap">
      <img class="bgImage" v-lazy="$utils.checkImg(item)" alt="" v-if="item.attach_image || item.attach_image_url" />
      <img class="bgImage" src="@/assets/blog.png" alt="" v-else />
      <h3 class="item-title">{{ item.title }}</h3>
    </div>

    <div class="item-bottom">
      <van-tag class="tips-tag">{{ item.insight_category }}</van-tag>
      <div class="tips-wrap">
        <p class="view">阅读 {{ item.pv_count }}</p>
        <div class="like-wrap" @click.stop="handleLike">
          <i class="iconfont icon-like " :class="{ 'is-like': isLike }"></i>
          <p class="like">{{ likeCount }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    item: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      isLike: this.item.liked,
      likeCount: this.item.like_count
    };
  },
  computed: {
    bgImage() {
      if (this.item.attach_image) {
        const img = this.item.attach_image;
        var remote = encodeURI(img);

        console.log(remote, 'remote');
        return remote;
      } else {
        return '';
      }
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.paramsLike = {
      source: 'Node',
      name: this.item.name
    };

    this.$bus.$on('handleLike', ({ id, type }) => {
      console.log(id, type, this.item, 'haha');
      if (id === this.item.name) {
        console.log('hahaha');
        this.likeStatus(type);
      }
    });
  },
  methods: {
    async handleLike() {
      await this.$api['searchArea/expertLike'](this.paramsLike);
      this.likeStatus(this.isLike);
    },
    likeStatus(flag) {
      if (flag) {
        this.isLike = false;
        this.likeCount -= 1;
      } else {
        this.isLike = true;
        this.likeCount += 1;
      }
    },
    goToDetail(item) {
      this.$router.push({
        path: `/expert/${item.name}`
      });
    },

    goToAuthor(item) {
      console.log('item', item);
      this.$router.push({
        path: `/author/${this.item.author_profile}`
      });
    }
  }
};
</script>

<style scoped lang="less">
p {
  margin: 0;
}
.item-wrap {
  padding: 16px 0;
  border-bottom: 1px solid #ccc;
  .bg-wrap {
    width: 100%;
    height: 160px;
    position: relative;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 10px 0 15px;
    border-radius: 6px;
    position: relative;
    .bgImage {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      object-fit: cover;
    }
    .item-title {
      background: rgba(0, 0, 0, 0.6);
      z-index: 0;
      padding: 10px;
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      color: #fff;
      margin: 0;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  .item-tips {
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
    color: #999;
    font-size: 14px;
    line-height: 30px;
    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      margin-right: 16px;
    }
  }
  .item-tips-author {
    margin-right: 16px;
    color: @primary-color;
    font-size: 14px;
    line-height: 30px;
  }
  .item-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .tips-tag {
      font-size: 13px;
      padding: 2px 10px;
      background: #ecf0fc;
      color: #4169e2;
    }
    .tips-wrap {
      min-width: 180px;
      padding: 0 18px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .view {
      }
      .like-wrap {
        display: flex;
        flex-direction: row;
        width: 40px;
        justify-content: space-between;
        align-items: center;
        i {
          color: #666;
        }
        .is-like {
          color: #fc8300;
        }
      }
    }
  }
}
</style>
